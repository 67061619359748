import React, { useState } from "react";
import {
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import {
  Visibility,
  VisibilityOff,
  FileCopyOutlined,
} from "@mui/icons-material";
import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";
import SoftInput from "website/portal/components/SoftInput";
import { auth } from "Api/Website/auth";
import { useDispatch, useSelector } from "react-redux";

function MaskedKey({
  keyValue,
  isVerified,
  setverified,
  isPasswordDialogOpen,
  setIsPasswordDialogOpen,
}) {
  const [showKey, setShowKey] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [password, setPassword] = useState("");
  const [err, setErr] = useState("");
  const token = useSelector((state) => state.token);

  const handleToggleVisibility = () => {
    if (isVerified) {
      setShowKey(!showKey);
      setIsPasswordDialogOpen(false);
    } else {
      setIsPasswordDialogOpen(true);
    }
  };
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(keyValue);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 1500); // Reset message after 1.5 seconds
  };

  const handlePasswordDialogClose = () => {
    setIsPasswordDialogOpen(false);
  };

  const handleSubmitPassword = async () => {
    try {
      if (password) {
        const verifyPass = await auth.verifyPass({ password }, token);
        if (verifyPass.success === true) {
          setIsPasswordDialogOpen(false);
          setverified(true);
          setShowKey(true);
        } else {
          setErr(verifyPass.message);
        }
      }
    } catch (error) {
      setErr(verifyPass.message);
    }
  };

  const maskedValue = "******************************"; // Show only last 4 characters masked
  const halfValue = `${keyValue.slice(0, Math.ceil(keyValue.length / 2.7))}...`;

  return (
    <SoftBox
      display="flex"
      alignItems="center"
      position="relative"
      width="100%"
    >
      <span style={{ width: "120px", flexGrow: 1 }}>
        {showKey ? halfValue : maskedValue}
      </span>

      {showKey && (
        <Tooltip
          title={copySuccess ? "Copied!" : "Copy"}
          sx={{ bgcolor: "#2152ff", color: "#fff" }}
        >
          <IconButton
            onClick={handleCopyToClipboard}
            size="small"
            sx={{ position: "relative" }}
          >
            <FileCopyOutlined fontSize="small" />
          </IconButton>
        </Tooltip>
      )}

      <IconButton
        onClick={handleToggleVisibility}
        size="small"
        sx={{ position: "relative" }}
      >
        {showKey ? (
          <VisibilityOff fontSize="small" />
        ) : (
          <Visibility fontSize="small" />
        )}
      </IconButton>

      <Dialog open={isPasswordDialogOpen} onClose={handlePasswordDialogClose}>
        <DialogTitle variant="h5">Verify Password</DialogTitle>
        <DialogContent sx={{ padding: "0px 22px" }}>
          <SoftInput
            margin="dense" // Adjust this to "dense" or "none"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <p className="text-danger">{err}</p>
        </DialogContent>
        <DialogActions sx={{ p: 0 }}>
          <Button
            type="submit"
            variant="contained"
            sx={{ mb: 2, color: "#fff", mr: 2, height: "20px", width: "16px" }}
            onClick={handleSubmitPassword}
          >
            verify
          </Button>
        </DialogActions>
      </Dialog>
    </SoftBox>
  );
}

export default MaskedKey;
