import Grid from "@mui/material/Grid";

// // Dashboard layout components
import Projects from "./components/Projects/";
import OrderOverview from "./components/OrderOverview";

function Dashboard() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} lg={8}>
        <Projects />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <OrderOverview />
      </Grid>
    </Grid>
  );
}

export default Dashboard;
