import React, { useState } from "react";
import "./style.css";
import Carousel from "react-bootstrap/Carousel";
import LandingNav from "./LandingNav";
import Reviews from "../components/reviews/Reviews";
import { Link } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_URL;

const services = [
  {
    title: "Consultation and Needs Assessment",
    description:
      "We start via know-how your business objectives, audience, and specific necessities. This initial session allows us to lay out a chatbot solution that aligns along with your dreams",
  },
  {
    title: "Platform Integration",
    description:
      "We ensure seamless integration of the chatbot with your present systems, which includes websites, cell apps, and social media channels. Our aim is to create a unified and cohesive customer revel in across all touchpoints.",
  },
  {
    title: "Training and Optimization",
    description:
      "To maximize the effectiveness of your chatbot, we provide complete schooling on your group. We additionally constantly reveal and optimize the chatbot's performance, making modifications primarily based on user comments and interaction information.",
  },
  {
    title: "Ongoing Support and Maintenance",
    description:
      "Our dedication would not give up on deployment. We provide ongoing aid and maintenance to ensure your chatbot stays up-to-date and capabilities flawlessly, adapting to new traits and client needs.",
  },
];

export default function Dashboard() {
  const [tabs, setTab] = useState(0);
  const handleTabs = (index) => {
    setTab(index);
  };
  return (
    <>
      <LandingNav />
      <section
        id="intro"
        className="py-5 mt-5"
        style={{ backgroundColor: "#D6DCE5" }}
      >
        <div className="container">
          <div className="row">
            <div className=" col-lg-6 col-md-6 px-5">
              <h1 className="introText text-start">
                AI{" "}
                <span className="introName" style={{ fontWeight: "500" }}>
                  Chatbot{" "}
                </span>
                Integration For Website
              </h1>

              <p className="introPara text-start">
                With your business in mind, Welcome to QuickAssistant a company
                that specializes in frictionless chatbot integration into your
                customer experience and operations. Communication may be the key
                to success in the contemporary world, it is imperative. In any
                organization including writing a blog, communication must be
                very effective as long as you do have anything to say by your
                written or spoken words. The customers with our sophisticated
                chatbot solutions are in your nearest area and provide immediate
                individualized assistance to reduce the burden on your human
                contact center agents.
              </p>
              <Link
                to={"/signup"}
                className="btn btn-secondary btn-chat large-btn mb-3"
              >
                Create your AI-Chat for free
              </Link>
              <ul className="list-unstyled text-start">
                <li className="main-section">Live in less than 3 minutes</li>
                <li className="main-section">Trained on your content</li>
                <li className="main-section">7-days free trial</li>
              </ul>
            </div>
            <div className="col-lg-6 col-md-6">
              <div id="main-script-img" className="m-auto">
                <img
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                    borderRadius: "25px",
                  }}
                  src={`https://quickassistant.co/public/static/main.png`}
                  alt=""
                  className="bg"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="first-main" className="py-4 bg-light">
        <h2 className="text-center px-4">
          Why Should You Integrate an AI Chatbot on Your Website?
        </h2>
        <div className="px-5">
          <div className="container">
            <p>
              Chatbots have drastically changed how businesses interact with
              their customers. And if you are still in doubt, here are a few
              reasons that might help you reconsider incorporating an automated
              chatbot for website.
            </p>
            <div className="row">
              <div className="col-lg-6  col-sm-12 mb-3">
                <div className="card service-cards shadow bg-white">
                  <div className="py-3 px-3">
                    <h6>
                      <i
                        className="fa-solid fa-headset"
                        style={{ fontSize: "20px" }}
                      ></i>{" "}
                      24/7 Customer Support
                    </h6>
                    <p>
                      Customers want answers fast and nowadays you can find a
                      response 24x7 using a chatbot. Answer FAQs, help customers
                      navigate between processes, and give direct answers to
                      even the most basic product needs 24/7.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6  col-sm-12 mb-3">
                <div className="card service-cards shadow bg-white">
                  <div className="py-3 px-3">
                    <h6>
                      <i
                        className="fa-solid fa-users"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>{" "}
                      Enhanced Customer Engagement
                    </h6>
                    <p>
                      Offer assistance through proactive automated chatbots for
                      websites that engage website visitors based on their
                      behavior and preferences, even suggesting personalized
                      recommendations. This not only makes the user experience
                      more pleasant but also raises the conversion probability.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6  col-sm-12 mb-3">
                <div className="card service-cards shadow bg-white">
                  <div className="py-3 px-3">
                    <h6>
                      <i
                        className="fa-solid fa-credit-card"
                        style={{ fontSize: "20px" }}
                      ></i>{" "}
                      Cost-Efficiency
                    </h6>
                    <p>
                      Spared Chance and Cash - Since chatbots systemize the
                      schedule yet significant undertakings, they can spare a
                      lot of client care staff cash that would have been put
                      resources into group (re)training. This will help reduce
                      the load on your human agents so that they can concentrate
                      on more complex and constructive interactions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6  col-sm-12 mb-3">
                <div className="card service-cards shadow bg-white">
                  <div className="py-3 px-3">
                    <h6>
                      <i
                        className="fa-solid fa-layer-group"
                        style={{ fontSize: "20px" }}
                      ></i>{" "}
                      Scalability
                    </h6>
                    <p>
                      Cover time, as your business scales, you will need more
                      customer support. Chatbots are easily scalable to manage
                      more interactions without the quality of service being
                      degraded leading to all users having a uniform experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-6  col-sm-12 mb-3">
                <div className="card service-cards shadow bg-white">
                  <div className="py-3 px-3">
                    <h6>
                      <i
                        className="fa-solid fa-database"
                        style={{ fontSize: "20px" }}
                      ></i>{" "}
                      Data Collection and Insights
                    </h6>
                    <p>
                      Chatbots provide a special data source on customer
                      interactions, preferences and pain points. This
                      information can be leveraged for product and service
                      enhancements as well as customer strategies, to set your
                      business apart from the crowd.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="services"
        className=" py-3"
        style={{ backgroundColor: "#D6DCE5" }}
      >
        <div className="container px-5">
          <h2 className="text-center">QuickAssistant Integration Services</h2>
          <p className="text-center">
            We offer complete chatbot integration offerings tailored for your
            specific business desires. Our method consists of:
          </p>
          <ul className="tab-list nav nav-pills justify-content-center">
            {services.map((item, index) => {
              return (
                <li
                  className="tab-list__item"
                  key={index}
                  onClick={(event) => {
                    handleTabs(index);
                  }}
                >
                  <a
                    className={`tab-list__link ${
                      tabs === index ? "active" : ""
                    }`}
                    data-toggle="tab"
                    aria-expanded="false"
                  >
                    <span className={`step ${tabs === index ? "active" : ""}`}>
                      {index + 1}
                    </span>
                    <span className="desc">{item.title}</span>
                  </a>
                </li>
              );
            })}
          </ul>
          <div className="tab-content">
            <Carousel indicators={false} controls={false} activeIndex={tabs}>
              {services.map((item, index) => {
                return (
                  <Carousel.Item key={index}>
                    <div
                      className={`tab-pane ${
                        tabs === index ? "active" : "hide"
                      } ${tabs}`}
                      key={index}
                    >
                      <div className="card h-auto shadow bg-white rounded">
                        <div className="card-body">
                          <p className="card-text">{item.description}</p>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </div>
        </div>
      </section>
      <section id="industries" className="py-4 bg-light">
        <div className="container px-5">
          <h2 className="text-center">Industries We Serve</h2>
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div
                className="industries-card  mb-3 card shadow border-none text-center py-2"
                style={{ backgroundColor: "#e68613" }}
              >
                <div>
                  <i className="fa fa-light fa-cart-shopping "></i>
                  <h5> E-Commerce</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div
                className="industries-card  mb-3 card shadow border-none text-center py-2"
                style={{ backgroundColor: "rgb(220 53 69)" }}
              >
                {" "}
                <div>
                  <i className="fa fa-light fa-suitcase-medical "></i>
                  <h5>Healthcare</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div
                className="industries-card  mb-3 card shadow border-none text-center py-2"
                style={{ backgroundColor: "#587edf" }}
              >
                <div>
                  <i className="fa fa-light fa-coins "></i>
                  <h5>Banking and Finance</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div
                className="industries-card  mb-3 card shadow border-none text-center py-2"
                style={{ backgroundColor: "#707070" }}
              >
                <div>
                  <i className="fa fa-light fa-car "></i> <h5>Travel</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div
                className="industries-card  mb-3 card shadow border-none text-center py-2"
                style={{ backgroundColor: "#7e3793" }}
              >
                <div>
                  <i className="fa fa-light fa-book "></i> <h5>Education</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div
                className="industries-card  mb-3 card shadow border-none text-center py-2"
                style={{ backgroundColor: "#00BE67" }}
              >
                <div>
                  <i className="fa  fa-light fa-utensils"></i>
                  <h5>Food</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="reviews"
        className="py-4"
        style={{ backgroundColor: "#D6DCE5" }}
      >
        <h2 className="text-center">
          What Our Happy Customers Say About QuickAssistant.co
        </h2>
        <Reviews />
      </section>
      <section
        id="pricing"
        className="pricing-content section-padding py-3 py-md-5"
      >
        <div className="container">
          <div className="section-title text-center">
            <h2>Pricing Plan</h2>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout.
            </p>
          </div>
          <div className="row text-center">
            <div
              className="col-lg-4 col-sm-4 col-xs-12 wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.1s"
              data-wow-offset="0"
            >
              <div className="single-pricing rounded py-3">
                <div className="price-head">
                  <h2>Starter</h2>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <h3 className="price">$10</h3>
                <h5>Monthly</h5>
                <ul>
                  <li>5 website</li>
                  <li>50GB Disk Space</li>
                  <li>50 Email Accounts</li>
                </ul>
                <a href="#">Get start</a>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-4 col-xs-12 wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
              data-wow-offset="0"
            >
              <div className="single-pricing rounded py-3">
                <div className="price-head">
                  <h2>popular</h2>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <h3 className="price">$19</h3>
                <h5>Monthly</h5>
                <ul>
                  <li>10 website</li>
                  <li>50GB Disk Space</li>
                  <li>50 Email Accounts</li>
                  <li>50GB Monthly Bandwidth</li>
                  <li>10 Subdomains</li>
                </ul>
                <a href="#">Get start</a>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-4 col-xs-12 wow fadeInUp "
              data-wow-duration="1s"
              data-wow-delay="0.3s"
              data-wow-offset="0"
            >
              <div className="single-pricing single-pricing-white rounded py-3">
                <div className="price-head">
                  <h2>Advance</h2>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <span className="price-label">Best</span>
                <h3 className="price">$30</h3>
                <h5>Monthly</h5>
                <ul>
                  <li>15 website</li>
                  <li>50GB Disk Space</li>
                  <li>50 Email Accounts</li>
                  <li>50GB Monthly Bandwidth</li>
                  <li>10 Subdomains</li>
                  <li>15 Domains</li>
                  <li>Unlimited Support</li>
                </ul>
                <a href="#">Get start</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-3 py-md-5" style={{ backgroundColor: "#D6DCE5" }}>
        <div className="container">
          <div className="row gy-5 gy-lg-0 align-items-lg-center">
            <div className="col-12 col-lg-6 faq-image">
              <img
                className="img-fluid rounded"
                loading="lazy"
                src="faq.png"
                alt="How can we help you?"
              />
            </div>
            <div className="col-12 col-lg-6">
              <div className="row justify-content-xl-end">
                <div className="col-12 col-xl-11">
                  <h2 className="h1 mb-3">Frequently Asked Questions?</h2>
                  <p className="lead fs-4 text-secondary mb-5"></p>
                  <div
                    className="accordion accordion-flush"
                    id="accordionExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseZero"
                          aria-expanded="true"
                          aria-controls="collapseZero"
                        >
                          What is a website chatbot?{" "}
                        </button>
                      </h2>
                      <div
                        id="collapseZero"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            A website chatbot is an AI-powered tool integrated
                            into a website to interact with visitors in
                            real-time. It can answer questions, provide
                            information, and assist with various tasks,
                            enhancing user experience and streamlining customer
                            service.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                        >
                          How can a chatbot benefit my website?
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            A chatbot can benefit your website by providing
                            instant customer support, increasing engagement,
                            reducing bounce rates, capturing leads, and
                            improving overall user satisfaction. It can handle
                            multiple inquiries simultaneously, saving time and
                            resources.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          What features should I look for in the best AI website
                          chatbot?
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          When choosing the best website chatbot, look for
                          features like natural language processing (NLP), 24/7
                          availability, easy integration, customization options,
                          analytics, multi-language support, and the ability to
                          handle various types of queries.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Are website chatbots easy to integrate?
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            Most website chatbots are designed for easy
                            integration. They typically offer plugins or widgets
                            that can be added to your website with minimal
                            coding knowledge. Many also provide step-by-step
                            guides and customer support to assist with the
                            integration process.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          Can a chatbot handle complex queries?{" "}
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            Advanced AI chatbots with robust NLP capabilities
                            can handle complex queries by understanding context,
                            recognizing intent, and providing accurate
                            responses. However, for highly specialized or
                            sensitive issues, human intervention may still be
                            required.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          How customizable are website chatbots?
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFive"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            Website chatbots are highly customizable. You can
                            tailor them to match your brand’s voice, appearance,
                            and functionality. Customization options often
                            include scripting responses, creating decision
                            trees, and setting up specific triggers and actions.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light py-3 py-md-5" id="contact-us">
        <div className="container">
          <div className="row gy-3 gy-md-4 gy-lg-0 align-items-md-center">
            <div className="col-12 col-lg-6">
              <div className="row justify-content-xl-center">
                <div className="col-12 col-xl-11">
                  <h2 className="h1 mb-3">Get in touch</h2>
                  <p className="lead fs-4 text-secondary mb-5">
                    We're always on the lookout to work with new clients. If
                    you're interested in working with us, please get in touch in
                    one of the following ways.
                  </p>

                  <div className="row mb-5">
                    <div className="col-12 col-sm-6">
                      <div className="d-flex mb-0">
                        <div className="me-4 text-primary">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            fill="currentColor"
                            className="bi bi-envelope-at"
                            viewBox="0 0 16 16"
                          >
                            <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z" />
                            <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648Zm-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z" />
                          </svg>
                        </div>
                        <div>
                          <h4 className="mb-3">E-Mail</h4>
                          <p className="mb-0">
                            <a
                              className="link-secondary text-decoration-none"
                              href="mailto:support@quickassistant.co"
                            >
                              support@quickassistant.co
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="bg-white border rounded shadow-sm overflow-hidden">
                <form action="#!">
                  <div className="row gy-4 gy-xl-5 p-4 p-xl-5">
                    <div className="col-12">
                      <label htmlFor="fullname" className="form-label">
                        Full Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="fullname"
                        name="fullname"
                        value=""
                        required
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="email" className="form-label">
                        Email <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-envelope"
                            viewBox="0 0 16 16"
                          >
                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                          </svg>
                        </span>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          value=""
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="phone" className="form-label">
                        Phone Number
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-telephone"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                          </svg>
                        </span>
                        <input
                          type="tel"
                          className="form-control"
                          id="phone"
                          name="phone"
                          value=""
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <label htmlFor="subject" className="form-label">
                        Subject <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="subject"
                        name="subject"
                        value=""
                        required
                      />
                    </div>
                    <div className="col-12">
                      <label htmlFor="message" className="form-label">
                        Message <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        id="message"
                        name="message"
                        rows="3"
                        required
                      ></textarea>
                    </div>
                    <div className="col-12">
                      <div className="d-grid">
                        <button
                          className="btn btn-primary btn-lg"
                          type="submit"
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="footer" style={{ backgroundColor: "#D6DCE5" }}>
        <div className="container">
          <footer className="py-5">
            <div className="row">
              <div className="col-6 col-md-2 mb-3">
                <h5>Section</h5>
                <ul className="nav flex-column">
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0 text-body-secondary">
                      Home
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0 text-body-secondary">
                      Features
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0 text-body-secondary">
                      Pricing
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0 text-body-secondary">
                      FAQs
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0 text-body-secondary">
                      About
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-6 col-md-2 mb-3">
                <h5>Section</h5>
                <ul className="nav flex-column">
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0 text-body-secondary">
                      Home
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0 text-body-secondary">
                      Features
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0 text-body-secondary">
                      Pricing
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0 text-body-secondary">
                      FAQs
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0 text-body-secondary">
                      About
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-6 col-md-2 mb-3">
                <h5>Section</h5>
                <ul className="nav flex-column">
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0 text-body-secondary">
                      Home
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0 text-body-secondary">
                      Features
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0 text-body-secondary">
                      Pricing
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0 text-body-secondary">
                      FAQs
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0 text-body-secondary">
                      About
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-md-5 offset-md-1 mb-3">
                <form>
                  <h5>Subscribe to our newsletter</h5>
                  <p>Monthly digest of what's new and exciting from us.</p>
                  <div className="d-flex flex-column flex-sm-row w-100 gap-2">
                    <label htmlFor="newsletter1" className="visually-hidden">
                      Email address
                    </label>
                    <input
                      id="newsletter1"
                      type="text"
                      className="form-control"
                      placeholder="Email address"
                    />
                    <button className="btn btn-primary" type="button">
                      Subscribe
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div className="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">
              <p>© 2024 Company, Inc. All rights reserved.</p>
              <ul className="list-unstyled d-flex">
                <li className="ms-3">
                  <a className="link-body-emphasis" href="#">
                    <svg className="bi" width="24" height="24">
                      <use></use>
                    </svg>
                  </a>
                </li>
                <li className="ms-3">
                  <a className="link-body-emphasis" href="#">
                    <svg className="bi" width="24" height="24"></svg>
                  </a>
                </li>
                <li className="ms-3">
                  <a className="link-body-emphasis" href="#">
                    <svg className="bi" width="24" height="24"></svg>
                  </a>
                </li>
              </ul>
            </div>
          </footer>
        </div>
      </section>
    </>
  );
}
