import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, Box, Typography, Avatar } from "@mui/material";

import SoftBox from "./components/SoftBox";
import Sidenav from "./examples/Sidenav";
import Configurator from "./examples/Configurator";
import theme from "./assets/theme";
import routes from "./routes";
import {
  useSoftUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "./context";
import LandingPage from "../pages/Dashboard";
import DashboardNavbar from "./examples/Navbars/DashboardNavbar";
import DashboardLayout from "./examples/LayoutContainers/DashboardLayout";
import Footer from "./examples/Footer";
import CreateReply from "./layouts/AutoReply/CreateReply";
import Signup from "../pages/Auth/Signup";
import Login from "../pages/Auth/Login";
import ProtectedRoute from "./ProtectedRoute";
import RedirectRoute from "../../RedirectRoute";
import Loader from "./components/Loader";
import SettingsIcon from "@mui/icons-material/Settings";
import { useDispatch, useSelector } from "react-redux";
import { SET_USER_DATA, SET_TOKEN } from "store";
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

function Portal() {
  const userData = useSelector((state) => state.userData);

  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } =
    controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  const [load, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    const loadQuickAssistScript = async () => {
      // Dynamically load QuickAssist script
      const REACT_CHAT_BOT = process.env.REACT_CHAT_BOT || "qk_cid6684305f8649a249ea2608ff904c240a-d60b-4f27-ab40-7135cff37f9b0";
      console.log({ REACT_APP_API_URL, REACT_CHAT_BOT });
      const script = document.createElement("script");
      script.src = `${REACT_APP_API_URL}/dist/bundle.js`;
      script.async = true;
      script.onload = async () => {
        try {
          const quickAssist = await window.QuickAssist.init(REACT_CHAT_BOT);
          if (quickAssist.status === 200 && quickAssist.data.success === true) {
            const style = {
              logoBorder: "50%",
              // backgroundColor: "red",
              // leftMessageColor: "#d6dce5",
            };
            const attr = {
              logo: "https://quickassistant.co/logo.png",
            };
            await quickAssist.addMyApi(`${REACT_APP_API_URL}/api/submit_data`);
            quickAssist.addSupportChat(style, attr);
          }
        } catch (error) {
          console.error("Error initializing QuickAssist:", error);
        } finally {
          setLoading(false);
        }
      };
      document.body.appendChild(script);
    };

    // if (userData && userData.clients.length > 0) {
    loadQuickAssistScript();
    // }
  }, []);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route key={route.key} path={route.route} element={route.component} />
        );
      }

      return null;
    });

  return (
    <>
      <div
        id="bot-integration"
        style={{
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "3.5rem",
          height: "3.5rem",
          bgColor: "white",
          shadow: "sm",
          borderRadius: "50%",
          position: "fixed",
          right: "3rem",
          bottom: "2rem",
          zIndex: 99,
          color: "dark",
        }}
      ></div>
      <ThemeProvider theme={theme}>
        {controller?.loader === true && <Loader />}
        {load && !["/"].includes(pathname) && (
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              bgcolor: "#fff",
              zIndex: 9999999,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box>
              <Box sx={{ height: "200px", width: "200px" }}>
                <img
                  src="/logo.png"
                  alt="QuickAssistant"
                  style={{ height: "100%", width: "100%", color: "#fff" }}
                />
              </Box>
              <Typography variant="h6" color="white">
                <div
                  style={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <span style={{ fontSize: "24px", fontWeight: "bold" }}>
                    Quick
                  </span>
                  <span style={{ fontSize: "12px", marginTop: "-10px" }}>
                    Assistant
                  </span>
                </div>
              </Typography>
            </Box>
          </Box>
        )}
        <CssBaseline />
        {!["/login", "/", "/signup"].includes(pathname) && (
          <>
            <>
              <Sidenav
                color={sidenavColor}
                brandName="Quick Assistant"
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator />
            </>
            {layout === "vr" && <Configurator />}
          </>
        )}
        <Routes>
          {["/login", "/", "/signup"].includes(pathname) ? (
            <>
              <Route path="/" element={<LandingPage />} />
              <Route
                path="/login"
                element={
                  <RedirectRoute>
                    <Login />
                  </RedirectRoute>
                }
              />
              <Route
                path="/signup"
                element={
                  <RedirectRoute>
                    <Signup />
                  </RedirectRoute>
                }
              />
            </>
          ) : (
            <Route
              path="/*"
              element={
                <SoftBox pt={3}>
                  <DashboardLayout>
                    <DashboardNavbar />
                    <SoftBox pt={3} py={3}>
                      <ProtectedRoute>
                        <Routes>
                          {getRoutes(routes)}
                          <Route
                            exact
                            path="/dashboard/create-reply"
                            element={<CreateReply />}
                            key="Auto Reply"
                          />
                        </Routes>
                      </ProtectedRoute>
                    </SoftBox>
                    <Footer />
                  </DashboardLayout>
                </SoftBox>
              }
            />
          )}
        </Routes>
      </ThemeProvider>
    </>
  );
}

export default Portal;
