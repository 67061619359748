import { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { AppBar, Toolbar, IconButton, Menu } from "@mui/material";
import SoftBox from "../../../components/SoftBox";
import SoftInput from "../../../components/SoftInput";
import Breadcrumbs from "../../../examples/Breadcrumbs";
import NotificationItem from "../../../examples/Items/NotificationItem";
import {
  Search as SearchIcon,
  AccountCircle as AccountCircleIcon,
  Menu as MenuIcon,
  MenuOpen as MenuOpenIcon,
  Settings as SettingsIcon,
  Notifications as NotificationsIcon,
  Payments,
  Home,
} from "@mui/icons-material";
// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "../../../examples/Navbars/DashboardNavbar/styles";

// Soft UI Dashboard React context
import {
  useSoftUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "../../../context";

// Images
import team2 from "../../../assets/images/team-2.jpg";
import logoSpotify from "../../../assets/images/small-logos/logo-spotify.svg";
import ToggelProfile from "../../../assets/theme/components/profileTab/ToggelProfile";
import { Alert, Stack, Button } from "@mui/material";
import SoftButton from "website/portal/components/SoftButton";
import { SET_USER_DATA } from "store";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "Api/Website/auth";

function DashboardNavbar({ absolute, light, isMini }) {
  const dispatchRedux = useDispatch();
  const userData = useSelector((state) => state.userData);
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } =
    controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    window.addEventListener("scroll", handleTransparentNavbar);
    handleTransparentNavbar();
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);
  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e, route = null) => {
    if (route == "logout") {
      dispatchRedux({ type: SET_USER_DATA, payload: null });
      sessionStorage.clear(); // Ensure response.token is correct
      navigate("/login");
    }
    setAnchorEl(null);
  };

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        image={<img src={team2} alt="person" />}
        title={["New message", "from Laur"]}
        date="13 minutes ago"
        onClick={handleCloseMenu}
      />
      <NotificationItem
        image={<img src={logoSpotify} alt="person" />}
        title={["New album", "by Travis Scott"]}
        date="1 day"
        onClick={handleCloseMenu}
      />
      <NotificationItem
        color="secondary"
        image={
          <Payments
            fontSize="small"
            sx={{ color: ({ palette: { white } }) => white.main }}
          ></Payments>
        }
        title={["", "Payment successfully completed"]}
        date="2 days"
        onClick={handleCloseMenu}
      />
    </Menu>
  );
  
  const handleverification = async () => {
    try {
      const token = useSelector((state) => state.token);
      const verify = await auth.sendVerification(token);
      if(verify.success === true){
        dispatchRedux({
          type: SET_USER_DATA,
          payload: {
            ...userData,
            mailSent: true,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  return (
    <>
      {(userData?.isVerify === undefined || userData?.isVerify === false) && (
        <Alert severity="error" sx={{ alignItems: "center", my: 2 }}>
          <div style={{ display: "flex", width: "100%",overflow:'hidden' }}>
            <div style={{ flex: 1 }}>
              <p style={{ marginBottom: 0,lineHeight:2 }}>
                Verify your account
              </p>
            </div>
            <SoftButton
              color="info"
              variant="gradient"
              size="small"
              onClick={handleverification}
            >
              {(!userData?.mailSent || userData?.mailSent === false) ? "Verify":"Mail sent"}
            </SoftButton>
          </div>
        </Alert>
      )}
      <AppBar
        position={absolute ? "absolute" : navbarType}
        color="inherit"
        sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
      >
        <Toolbar sx={(theme) => navbarContainer(theme)}>
          <SoftBox
            color="inherit"
            mb={{ xs: 1, md: 0 }}
            sx={(theme) => navbarRow(theme, { isMini })}
          >
            <Breadcrumbs
              icon={<Home />}
              title={route[route.length - 1]}
              route={route}
              light={light}
            />
          </SoftBox>
          {isMini ? null : (
            <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
              <SoftBox pr={1}>
                <SoftInput
                  placeholder="Type here..."
                  icon={{ component: <SearchIcon />, direction: "left" }}
                />
              </SoftBox>
              <SoftBox color={light ? "white" : "inherit"}>
                <IconButton
                  sx={navbarIconButton}
                  size="small"
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <AccountCircleIcon
                    sx={({ palette: { dark, white } }) => ({
                      color: light ? white.main : dark.main,
                    })}
                  />
                </IconButton>
                <IconButton
                  size="small"
                  color="inherit"
                  sx={navbarMobileMenu}
                  onClick={handleMiniSidenav}
                >
                  {miniSidenav ? (
                    <MenuOpenIcon
                      className={light ? "text-white" : "text-dark"}
                    />
                  ) : (
                    <MenuIcon className={light ? "text-white" : "text-dark"} />
                  )}
                </IconButton>
                <IconButton
                  size="small"
                  color="inherit"
                  sx={navbarIconButton}
                  onClick={handleConfiguratorOpen}
                >
                  <SettingsIcon />
                </IconButton>
                <IconButton
                  size="small"
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenMenu}
                >
                  <NotificationsIcon
                    className={light ? "text-white" : "text-dark"}
                  >
                    notifications
                  </NotificationsIcon>
                </IconButton>
                {renderMenu()}
              </SoftBox>
              <ToggelProfile
                open={open}
                anchorEl={anchorEl}
                handleClose={handleClose}
                handleClick={handleClick}
              />
            </SoftBox>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
