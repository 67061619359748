import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Grid, CardMedia, Card, Typography, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SoftButton from "../../components/SoftButton";
import { useSoftUIController } from "../../context";
import { AutoReply as Reply } from "Api/Website/AutoReply";
import Toaster from "website/portal/components/Toaster";
import { useDispatch, useSelector } from "react-redux";

export default function AutoReply() {
  const [controller] = useSoftUIController();
  const { userData } = controller;
  const [error, setError] = React.useState("");
  const [reply, setreply] = useState([]);
  const token = useSelector((state) => state.token);

  const fetchreplies = async () => {
    try {
      const data = await Reply.get(token);
      if (data.success === true) {
        console.log(data.data);
        setreply(data.data);
      } else {
        setError(data.message);
      }
    } catch (error) {
      setError("Internal Server Error");
    }
  };
  useEffect(() => {
    fetchreplies();
  }, []);
  return (
    <>
      {error && (
        <Toaster message={error} openToast={true} setError={setError} />
      )}
      <Grid container justifyContent={"end"} spacing={4} mb={3}>
        <SoftButton color="info" variant="outlined">
          <Link to="/dashboard/create-reply">Create Auto reply</Link>
        </SoftButton>
      </Grid>
      {reply.map((autoreply, index) => (
        <Card
          sx={{
            height: "94px",
            display: "flex",
            alignItems: "center",
            paddingLeft: "20px",
            marginBottom: "12px",
            cursor: "pointer",
          }}
          key={autoreply._id || index}
        >
          <Grid container>
              <Grid item xs={10} p={3}>
                <Link href={`/dashboard/chat/autoreply/${autoreply._id}/edit`}>
                  <Typography variant="h6" sx={{ textAlign: "start" }}>
                    {autoreply?.title}
                    <CardMedia
                      component="img"
                      image={
                        autoreply.enabled
                          ? "https://next.gatsby.run/active.svg"
                          : "https://next.gatsby.run/inactive.svg"
                      }
                      sx={{
                        width: "14px",
                        display: "inline",
                        "vertical-align": "middle",
                        ml: 1,
                        mt: 0,
                      }}
                    />
                  </Typography>
                </Link>
              </Grid>

              <Grid item xs={2} p={3} sx={{ textAlign: "end" }}>
                <Button
                  startIcon={<DeleteIcon fontSize="small" />}
                  // onClick={() => handleDeleteAutoreplyTemplate("autoreply")}
                ></Button>
              </Grid>
          </Grid>
        </Card>
      ))}
    </>
  );
}
