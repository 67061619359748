import * as React from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom"; // Import useNavigate and Link
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { auth } from "../../../Api/Website/auth";
import {
  fetchIP,
  fetchLocation,
  getBrowserName,
  getOperatingSystem,
} from "./commonFile";
import { useDispatch, useSelector } from "react-redux";
import { SET_USER_DATA,SET_TOKEN } from "../../../store";
import Toaster from "../../portal/components/Toaster";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" to={"/"}>
        QuickAssistant
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignIn() {
  const navigate = useNavigate(); // Initialize useNavigate hook
  const [error, setError] = React.useState("");
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [location, setLocation] = React.useState({
    city: "",
    region: "",
    country: "",
    ip: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsDisabled(true);

    const data = new FormData(event.currentTarget);

    const { ip } = await fetchIP();
    const { city, country } = await fetchLocation();
    const browserName = getBrowserName();
    const operatingSystem = getOperatingSystem();

    try {
      const response = await auth.Login({
        email: data.get("email"),
        password: data.get("password"),
        sessions: {
          deviceId: `${browserName} - ${operatingSystem}`,
          location: `${city} - ${country}`,
          ipAddress: ip,
        },
      });

      if (response.success === true) {
        dispatch({ type: SET_USER_DATA, payload: response.data });
        dispatch({ type: SET_TOKEN, payload: response.token });
        navigate("/dashboard");
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError("Something went wrong!");
    } finally {
      setIsDisabled(false);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      {error && (
        <Toaster message={error} openToast={true} setError={setError} />
      )}
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isDisabled}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <RouterLink to="/signup" variant="body2">
                  {"Don't have an account? Sign Up"}
                </RouterLink>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
