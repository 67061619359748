import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { Table as MuiTable, TableBody, TableContainer, TableRow } from "@mui/material";
import SoftBox from "../../../components/SoftBox";
import SoftAvatar from "../../../components/SoftAvatar";
import SoftTypography from "../../../components/SoftTypography";
import colors from "../../../assets/theme/base/colors";
import typography from "../../../assets/theme/base/typography";
import borders from "../../../assets/theme/base/borders";
import MaskedKey from "./MaskedKey"; // Import MaskedKey component

function Table({ columns, rows ,isPasswordDialogOpen, setIsPasswordDialogOpen, isVerified, setverified}) {
  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;
  const tableContent = useMemo(() => {
    const renderColumns = columns.map(({ name, align, width }, key) => {
      let pl;
      let pr;

      if (key === 0 || key === columns.length - 1) {
        pl = 3;
        pr = 3;
      } else {
        pl = 1;
        pr = 1;
      }

      return (
        <SoftBox
          key={name}
          component="th"
          width={width || "auto"}
          pt={1.5}
          pb={1.25}
          pl={align === "left" ? pl : 3}
          pr={align === "right" ? pr : 3}
          textAlign={align}
          fontSize={size.xxs}
          fontWeight={fontWeightBold}
          color="secondary"
          opacity={0.7}
          borderBottom={`${borderWidth[1]} solid ${light.main}`}
        >
          {name.toUpperCase()}
        </SoftBox>
      );
    });

    const renderRows = rows.map((row, key) => {
      const rowKey = `row-${key}`;
      const tableRow = columns.map(({ name, align }) => {
        let template;

        if (name === "clientId" || name === "clientSecret") {
          template = (
            <SoftBox
              key={uuidv4()}
              component="td"
              p={1}
              textAlign={align}
              borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
            >
              <MaskedKey keyValue={row[name]} setverified={setverified} isVerified={isVerified} setIsPasswordDialogOpen={setIsPasswordDialogOpen} isPasswordDialogOpen={isPasswordDialogOpen}/>
            </SoftBox>
          );
        } else if (Array.isArray(row[name])) {
          template = (
            <SoftBox
              key={uuidv4()}
              component="td"
              p={1}
              borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
            >
              <SoftBox display="flex" alignItems="center" py={0.5} px={1}>
                <SoftBox mr={2}>
                  <SoftAvatar src={row[name][0]} name={row[name][1]} variant="rounded" size="sm" />
                </SoftBox>
                <SoftTypography variant="button" fontWeight="medium" sx={{ width: "max-content" }}>
                  {row[name][1]}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          );
        } else if (React.isValidElement(row[name])) {
          template = (
            <SoftBox
              key={uuidv4()}
              component="td"
              p={1}
              textAlign={align}
              borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
            >
              {row[name]}
            </SoftBox>
          );
        } else {
          template = (
            <SoftBox
              key={uuidv4()}
              component="td"
              p={1}
              textAlign={align}
              borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
            >
              <SoftTypography
                variant="button"
                fontWeight="regular"
                color="secondary"
                sx={{ display: "inline-block", width: "max-content" }}
              >
                {row[name]}
              </SoftTypography>
            </SoftBox>
          );
        }

        return template;
      });

      return <TableRow key={rowKey}>{tableRow}</TableRow>;
    });

    return { renderColumns, renderRows };
  }, [columns, rows, light, size, fontWeightBold, borderWidth]);

  return (
    <TableContainer>
      <MuiTable>
        <SoftBox component="thead">
          <TableRow>{tableContent.renderColumns}</TableRow>
        </SoftBox>
        <TableBody>{tableContent.renderRows}</TableBody>
      </MuiTable>
    </TableContainer>
  );
}

// Setting default values for the props of Table
Table.defaultProps = {
  columns: [],
  rows: [{}],
};

// Typechecking props for the Table
Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
};

export default Table;
