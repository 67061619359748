import * as React from "react";
import { MenuItem, Menu } from "@mui/material";
import { Link } from "react-router-dom";
export default function BasicMenu({ anchorEl, open, handleClose }) {
  return (
    <div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={(e)=>{
          handleClose(e,null)
        }}>
        <Link to={"/dashboard/profile"}>
          Profile
        </Link>
        </MenuItem>
        <MenuItem onClick={(e)=>{
          handleClose(e,"logout")
        }}>Logout</MenuItem>
      </Menu>
    </div>
  );
}
