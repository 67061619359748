import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function LandingNav() {
  const [openMenu, setOpenMenu] = useState(false);
  return (
    <div className="container">
      <nav
        className="navbar navbar-expand-lg navbar-transparent bg-light shadow fixed-top"
        style={{ padding: "8px 10px 8px 10px" }}
      >
        <Link to={"/"} className="navbar-brand d-flex mx-3">
          <div style={{ height: "50px", width: "50px" }}>
            <img
              src="/logo.png"
              alt="quickassistant"
              style={{ height: "100%", width: "100%" }}
            />
          </div>
          <div  style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}>
            <span style={{ fontSize: "24px", fontWeight: "bold" }}>Quick</span>
            <span style={{fontSize:'12px',marginTop:'-10px'}}>Assistant</span>
          </div>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          onClick={(event) => {
            event.preventDefault();
            setOpenMenu(!openMenu);
          }}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className={`collapse navbar-collapse justify-content-end text-center ${
            openMenu ? "show" : "hide"
          }`}
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
              <a className="nav-link" href="#pricing">
                Pricing <span className="sr-only"></span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#reviews">
                Reviews
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#blog">
                Blog
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#contact-us">
                Contact us
              </a>
            </li>
            {/* <li className="dropdown">
          <button
            className="btn btn-secondary dropdown-toggle bg-white border-0"
            type="button"
            onClick={(event) => {
              event.preventDefault();
              setOpenDropDown(!openDropDown);
            }}
          >
            <i className="fa-solid fa-user" style={{ color: "blue" }}></i>
          </button>
          <ul class={`dropdown-menu ${openDropDown ? "show" : "hide"}`}>
            <li>
              <a className="dropdown-item" href="#">
                Action
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Another action
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Something else here
              </a>
            </li>
          </ul>
        </li> */}

            <form className="form-inline my-2 my-lg-0" id="search-main">
              <input
                className="form-control no-focus-border mr-sm-2 empty"
                type="search"
                placeholder="&#xF002; Search"
                aria-label="Search"
                style={{
                  borderColor: "transparent",
                  fontWeight: "500",
                  background: "inherit",
                }}
              />
            </form>
            <li className="nav-item">
              <Link to={"/signup"} className="btn btn-secondary btn-chat">
                Start for free
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}
