import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

// Initial state
const initialState = {
  userData: null,
  token :null,
  // other initial state values
};

// Action types
const SET_USER_DATA = "SET_USER_DATA";
const SET_TOKEN = "SET_TOKEN";

// Reducer function
function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_DATA:
      return { ...state, userData: action.payload };
    // other cases
    case SET_TOKEN:
      return { ...state, token: action.payload };
    // other cases
    default:
      return state;
  }
}

// Redux Persist configuration
const persistConfig = {
  key: "root",
  storage,
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, reducer);

// Create Redux store
const store = createStore(persistedReducer);

// Create a persistor
const persistor = persistStore(store);

export { store, persistor, SET_USER_DATA, SET_TOKEN };
