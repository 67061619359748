import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";

export default function Loader() {
  const [dots, setDots] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots.length >= 3) {
          return ".";
        }
        return prevDots + ".";
      });
    }, 500); // Update every 500 milliseconds (0.5 seconds)

    return () => clearInterval(interval);
  }, []);
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        opacity: 2,
        background: "#706a6a80",
        zIndex: 9999999,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box>
        <Box sx={{ height: "200px", width: "200px" }}>
          <img
            src="/AnimationLoader.gif"
            alt="QuickAssistant"
            style={{ height: "100%", width: "100%", color: "#fff" }}
          />
        </Box>
        <Typography variant="h6" color="white">
          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <span style={{ fontSize: "24px" }}>
              Processing{dots}
            </span>
          </div>
        </Typography>
      </Box>
    </Box>
  );
}
