import React from "react";
import "./reviews.css";
const data = [
  {
    image: "https://mdbootstrap.com/img/Photos/Slides/img%20(22).jpg",
    caption: "Lucas",
    description:
      "I've tried many tools, but this chat bot for websites from Quick Assistant is a game changer! It’s intuitive, user-friendly, and has significantly boosted our customer engagement. Highly recommend it!",
    rating: 5,
  },
  {
    image: "https://mdbootstrap.com/img/Photos/Slides/img%20(23).jpg",
    caption: "Richard",
    description:
      "After testing several options, I can confidently say Quick Assistant is the best website chatbots out there. It understands customer queries accurately and provides helpful responses in real-time. A must-have for any business!",
    rating: 4,
  },
  {
    image: "https://mdbootstrap.com/img/Photos/Slides/img%20(22).jpg",
    caption: "Mark",
    description:
      "The best AI chatbot for website integration, hands down. It's smart, learns quickly, and has improved our customer satisfaction rates dramatically. A fantastic investment!",
    rating: 5,
  },
  {
    image: "https://mdbootstrap.com/img/Photos/Slides/img%20(22).jpg",
    caption: "Clara",
    description:
      "Quick Assistant’s customer support services are outstanding. This chatbot is highly customizable and has seamlessly integrated with our existing systems. Our website has never been more interactive.",
    rating: 5,
  },
  {
    image: "https://mdbootstrap.com/img/Photos/Slides/img%20(23).jpg",
    caption: "Nina white",
    description:
      "This automated chatbot for website functionality is simply brilliant. It handles customer inquiries efficiently, freeing up our team to focus on more complex tasks. Our customers love it.",
    rating: 5,
  },
  {
    image: "https://mdbootstrap.com/img/Photos/Slides/img%20(22).jpg",
    caption: "Henry",
    description:
      "This chat bot for websites provided by Quick Assistant have transformed our customer support. It’s 24/7 support, quick to respond, and incredibly helpful. Couldn't be happier with the results!",
    rating: 5,
  },
  {
    image: "https://mdbootstrap.com/img/Photos/Slides/img%20(22).jpg",
    caption: "Caleb",
    description:
      "I was skeptical at first, but this really is the best AI chatbot for customer support. It has drastically reduced our response time and enhanced user experience on our site. Worth every penny!",
    rating: 5,
  },
  {
    image: "https://mdbootstrap.com/img/Photos/Slides/img%20(22).jpg",
    caption: "Aiden",
    description:
      "We needed the best AI chatbot for our website, and this one exceeded our expectations. It’s smart, efficient, and integrates perfectly with our site. Our customer engagement has never been better!",
    rating: 5,
  },
];
export default function Reviews() {
  // Function to generate star rating
  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <i
          key={i}
          className={`fas fa-star${
            i <= rating ? " text-warning" : " text-dark"
          }`}
        ></i>
      );
    }
    return stars;
  };
  return (
    <div className="container py-2" id="ratings">
      <div className="owl-carousel owl-theme">
        {data.map((item, index) => {
          return (
            <div className="owl-item " key={index}>
              <div
                className="card mx-2 shadow px-3 py-3 d-flex flex-column"
                style={{ width: "300px", height: "400px" }}
              >
                <div className="mt-2">{renderStars(item.rating)}</div>
                <div className="main font-weight-bold pb-2 pt-1">
                  Great Service
                </div>
                <div className="testimonial">{item.description}</div>
                <div className="d-flex flex-row profile pt-4 mt-auto">
                  <div style={{ height: "80px", width: "80px" }}>
                    <img
                      style={{ height: "100%", width: "100%" }}
                      src={item.image}
                      alt={item.caption}
                      className="rounded-circle"
                    />
                  </div>
                  <div className="name">{item.caption}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
