import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const RedirectRoute = ({ children }) => {
  const validToken = useSelector((state) => state.token);
  const [isTokenChecked, setIsTokenChecked] = useState(false);

  useEffect(() => {
    setIsTokenChecked(true); // Mark the token check as completed
  }, [validToken]);

  if (!isTokenChecked) {
    return null; // or a loading spinner, while checking for token
  }

  if (validToken) {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
};

export default RedirectRoute;
