import PropTypes from "prop-types";

// @mui material components
import {Favorite} from "@mui/icons-material";

// Soft UI Dashboard React components
import SoftBox from "../../components/SoftBox";

// Soft UI Dashboard React base styles
import typography from "../../assets/theme/base/typography";

function Footer() {
  const { size } = typography;


  return (
    <SoftBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
      sx={{justifyContent:'center'}}
    >
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={1.5}
      >
        &copy; {new Date().getFullYear()}, made with
        <SoftBox fontSize={size.md} color="text" mb={-0.5} mx={0.25}>
          <Favorite color="inherit" fontSize="inherit">
          </Favorite>
        </SoftBox>
        by QuickAssistant.co
      </SoftBox>
    </SoftBox>
  );
}


// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
