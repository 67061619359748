import * as React from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom"; // Import useNavigate and Link
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { auth } from "../../../Api/Website/auth";
import {
  fetchIP,
  fetchLocation,
  getBrowserName,
  getOperatingSystem,
} from "./commonFile";
import { useDispatch } from "react-redux";
import { SET_USER_DATA, SET_TOKEN } from "../../../store";
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link component={RouterLink} color="inherit" to="/">
        QuickAssistant
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function SignIn() {
  const navigate = useNavigate(); // Initialize useNavigate hook
  const dispatch = useDispatch();

  const [isDisabled, setIsDisabled] = React.useState(false);
  const [location, setLocation] = React.useState({
    city: "",
    region: "",
    country: "",
    ip: "",
  });
  const [role, setRole] = React.useState("admin");
  const [terms, setTerms] = React.useState(false);
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    const getData = async () => {
      const data = await fetchIP();
      setLocation((prevLocation) => ({ ...prevLocation, ip: data.ip }));

      const locationFetch = await fetchLocation();
      setLocation((prevLocation) => ({
        ...prevLocation,
        city: locationFetch.city,
        region: locationFetch.regionName,
        country: locationFetch.country,
      }));
    };
    getData();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setIsDisabled(true);
    // Get browser name and operating system
    const browserName = getBrowserName();
    const operatingSystem = getOperatingSystem();
    const { city, country, ip } = location;
    const params = {
      userName: data.get("name") || "",
      email: data.get("email") || "",
      domain: data.get("domain") || "",
      password: data.get("password") || "",
      role,
      terms,
      sessions: [
        {
          deviceId: `${browserName} - ${operatingSystem}`,
          location: `${city} - ${country}`,
          ipAddress: ip,
        },
      ],
    };
    try {
      const response = await auth.Signup(params);
      if (response.success === "true") {
        dispatch({ type: SET_USER_DATA, payload: response.data });
        dispatch({ type: SET_TOKEN, payload: response.token });
        navigate("/dashboard"); // Navigate to /dashboard
      } else {
        setError("Something went wrong!");
      }
    } catch (error) {
      setError("Something went wrong!");
    } finally {
      setIsDisabled(false); // Re-enable the button
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <Grid container spacing={2} sx={{ justifyContent: "center" }}>
              <Grid item xs={4}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label="Full Name"
                  name="name"
                  autoComplete="name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="domain"
                  label="Domain"
                  name="domain"
                  autoComplete="domain"
                  autoFocus
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
              </Grid>
              <Grid item xs={8}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(event) => setTerms(!terms)}
                      color="primary"
                    />
                  }
                  label="Accept terms"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={isDisabled}
                >
                  Sign Up
                </Button>
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ justifyContent: "center" }}>
              <Grid item xs={8}>
                <RouterLink
                  to="/login"
                  variant="body2"
                  sx={{ cursor: "pointer" }}
                >
                  Already have an account? Login here
                </RouterLink>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ justifyContent: "center" }}>
              <Grid item xs={8}>
                <Typography variant="body2" sx={{ color: "red" }}>
                  {error}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
