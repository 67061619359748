// Soft UI Dashboard React layouts
import Dashboard from "./layouts/dashboard";
import AutoReply from "./layouts/AutoReply";
import Profile from "./layouts/Profile";
// Soft UI Dashboard React icons
import { ShoppingCart, Home, Settings as SettingsIcon, ExitToApp } from '@mui/icons-material';

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <Home size="12px" />,
    component: <Dashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Auto Replies",
    key: "replies",
    route: "/dashboard/replies",
    icon: <ShoppingCart size="12px" />,
    component: <AutoReply />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "Profile",
    route: "/dashboard/profile",
    icon: <SettingsIcon size="12px" />,
    component: <Profile />,
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "Logout",
  //   key: "logout",
  //   route: "/logout",
  //   icon: <ExitToApp size="12px" />,
  //   component: <Dashboard />,
  //   noCollapse: true,
  // },
];

export default routes;
