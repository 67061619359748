import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

// const token = sessionStorage.getItem("access_token");
class Reply {
  async get(token) {
    try {
      const response = await axios.post(
        `${apiUrl}/api/get-auto-reply`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async create(params,token) {
    try {
      const response = await axios.post(
        `${apiUrl}/api/create-auto-reply`,
        params,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
export const AutoReply = new Reply();
