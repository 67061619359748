import { Card } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
// Soft UI Dashboard React components
import SoftBox from "../../../../components/SoftBox";
import SoftTypography from "../../../../components/SoftTypography";
import {
  Notifications,
  Inventory2,
  Payments,
  VpnKey,
  Paid,
} from "@mui/icons-material";
// Soft UI Dashboard React examples
import TimelineItem from "../../../../examples/Timeline/TimelineItem";

function OrdersOverview() {
  return (
    <Card className="h-100">
      <SoftBox pt={3} px={3}>
        <SoftTypography variant="h6" fontWeight="medium">
          Orders overview
        </SoftTypography>
        <SoftBox mt={1} mb={2}>
          <SoftTypography variant="button" color="text" fontWeight="regular">
            <SoftTypography
              display="inline"
              variant="body2"
              verticalAlign="middle"
            >
              <ArrowUpwardIcon
                sx={{
                  fontWeight: "bold",
                  color: ({ palette: { success } }) => success.main,
                }}
              />
            </SoftTypography>
            &nbsp;
            <SoftTypography variant="button" color="text" fontWeight="medium">
              24%
            </SoftTypography>{" "}
            this month
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox p={2}>
        <TimelineItem
          color="success"
          icon={<Notifications />}
          title="$2400, Design changes"
          dateTime="22 DEC 7:20 PM"
        />
        <TimelineItem
          color="error"
          icon={<Inventory2 />}
          title="New order #1832412"
          dateTime="21 DEC 11 PM"
        />
        <TimelineItem
          color="warning"
          icon={<Payments />}
          title="New card added for order #4395133"
          dateTime="20 DEC 2:20 AM"
        />
        <TimelineItem
          color="primary"
          icon={<VpnKey />}
          title="New card added for order #4395133"
          dateTime="18 DEC 4:54 AM"
        />
        <TimelineItem
          color="dark"
          icon={<Paid />}
          title="New order #9583120"
          dateTime="17 DEC"
        />
      </SoftBox>
    </Card>
  );
}

export default OrdersOverview;
