import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

class Auth {
  async Signup(userData) {
    try {
      const response = await axios.post(
        `https://quickassistant.co/api/signup`,
        userData
      );
      return response.data;
    } catch (error) {
      throw error; // You can handle errors further up in your component
    }
  }
  async Login(userData) {
    try {
      const response = await axios.post(
        `https://quickassistant.co/api/login`,
        userData
      );
      return response.data;
    } catch (error) {
      throw error; // You can handle errors further up in your component
    }
  }
  async sendVerification(params) {
    try {
      const response = await axios.post(
        `https://quickassistant.co/api/send-verification`,
        {},
        {
          headers: {
            Authorization: `Bearer ${params}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async editProfile(params, token) {
    try {
      const response = await axios.post(
        `https://quickassistant.co/api/updateProfile`,
        params,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async generateKeys(token) {
    try {
      const response = await axios.get(
        `https://quickassistant.co/api/generateKeys`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async verifyPass(params, token) {
    try {
      const response = await axios.post(
        `https://quickassistant.co/api/verify-password`,
        params,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export const auth = new Auth();
