import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import { SoftUIControllerProvider } from "./website/portal/context";
import Portal from "./website/portal/Portal";

function App() {
  return (
    <BrowserRouter basename="/">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SoftUIControllerProvider>
            <Portal />
          </SoftUIControllerProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
