import React, { useState } from "react";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Field, Form, Formik } from "formik";
import SoftInput from "../../components/SoftInput";
import { AutoReply } from "Api/Website/AutoReply";
import Toaster from "website/portal/components/Toaster";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Custom styled component for TextField
const CustomStyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiFilledInput-root::after": {
    borderBottom: "0px solid #fefefe",
    left: 0,
    bottom: 0,
    content: '""',
    position: "absolute",
    right: 0,
    transform: "scaleX(0)",
    transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
    pointerEvents: "none",
  },
  "& .MuiFilledInput-root.Mui-focused::after": {
    transform: "scaleX(1)",
  },
}));

// Autocomplete Field component
const AutocompleteField = ({ field, form, ...props }) => (
  <Autocomplete
    {...props}
    id="rules.keywords"
    freeSolo
    multiple
    autoSelect
    options={[]}
    value={field.value}
    onChange={(event, value) => {
      form.setFieldValue(field.name, value);
    }}
    renderInput={(params) => (
      <CustomStyledTextField {...params} variant="filled" />
    )}
  />
);

export default function CreateReply() {
  const navigate = useNavigate(); // Initialize useNavigate hook

  const [isAdvancedMessage, setIsAdvancedMessage] = useState(false);
  const [error, setError] = React.useState("");
  const token = useSelector((state) => state.token);

  const handleSubmit = async (values, helpers) => {
    try {
      const data = {
        isAdvanced: values.isAdvanced,
        enabled: values.enabled,
        isDuplicate: values.isDuplicate,
        title: values.name,
        acknowledgeMessage: values.message,
        keywords: values?.rules?.keywords,
        header: values?.header,
        body: values?.body,
        autoReplyContent: values?.buttonText,
      };
      const create = await AutoReply.create(data,token);
      if (create.success === true) {
        navigate("/dashboard/replies");
      } else {
        setError(create.message);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <>
      {error && (
        <Toaster message={error} openToast={true} setError={setError} />
      )}
      <Formik
        initialValues={{
          enabled: false,
          isDuplicate: false,
          isAdvanced: false,
          name: "",
          message: "",
          rules: {
            keywords: [],
          },
          header: "",
          body: "",
          buttonText: "",
        }}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {(formikProps) => (
          <Form>
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid
                    item
                    container
                    md={isAdvancedMessage ? 9 : 12}
                    spacing={3}
                    paddingRight={5}
                  >
                    <Grid item md={isAdvancedMessage ? 6 : 4} xs={12}>
                      <FormControlLabel
                        label="Enabled"
                        labelPlacement="start"
                        control={
                          <Switch
                            color="primary"
                            name="enabled"
                            onChange={formikProps.handleChange}
                          />
                        }
                      />
                    </Grid>
                    <Grid item md={isAdvancedMessage ? 6 : 4} xs={12}>
                      <FormControlLabel
                        label="Allow duplicate replies"
                        labelPlacement="start"
                        control={
                          <Switch
                            color="primary"
                            name="isDuplicate"
                            onChange={formikProps.handleChange}
                          />
                        }
                      />
                    </Grid>
                    <Grid item md={isAdvancedMessage ? 6 : 4} xs={12}>
                      <FormControlLabel
                        label="Is this an Advanced Auto-Reply?"
                        labelPlacement="start"
                        control={
                          <Switch
                            checked={isAdvancedMessage}
                            onChange={() => {
                              setIsAdvancedMessage(!isAdvancedMessage);
                              formikProps.handleChange("isAdvanced");
                            }}
                            color="primary"
                            name="isAdvanced"
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{ textAlign: "left" }}
                      >
                        Name
                      </Typography>
                      <SoftInput
                        name="name"
                        placeholder="Name"
                        required
                        onChange={formikProps.handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{ textAlign: "left" }}
                      >
                        Acknowledgement Message
                      </Typography>
                      <SoftInput
                        name="message"
                        placeholder="Message"
                        required
                        onChange={formikProps.handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" sx={{ textAlign: "left" }}>
                        Supported Variables: {"{{username}}"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{ textAlign: "left" }}
                      >
                        Keywords
                      </Typography>
                      <Field
                        name="rules.keywords"
                        component={AutocompleteField}
                      />
                    </Grid>
                    {isAdvancedMessage && (
                      <>
                        <Grid item xs={12}>
                          <Typography
                            variant="subtitle1"
                            sx={{ textAlign: "left" }}
                          >
                            Header
                          </Typography>
                          <SoftInput
                            name="header"
                            placeholder="Header"
                            required
                            onChange={formikProps.handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="subtitle1"
                            sx={{ textAlign: "left" }}
                          >
                            Body
                          </Typography>
                          <SoftInput
                            name="body"
                            placeholder="Body"
                            required
                            onChange={formikProps.handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="subtitle1"
                            sx={{ textAlign: "left" }}
                          >
                            Auto-Reply Content
                          </Typography>
                          <SoftInput
                            name="buttonText"
                            placeholder="Button text"
                            required
                            onChange={formikProps.handleChange}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                  {isAdvancedMessage && (
                    <Grid
                      item
                      md={3}
                      sx={{
                        backgroundColor: "rgb(248, 248, 248)",
                        position: "relative",
                      }}
                    >
                      <Card
                        sx={{
                          width: 300,
                          backgroundColor: "rgb(38, 38, 38)",
                          position: "absolute",
                          bottom: 0,
                        }}
                      >
                        <CardContent sx={{ padding: "12px" }}>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: 600,
                              lineHeight: "16px",
                              fontFamily:
                                '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
                              color: "rgb(245, 245, 245)",
                            }}
                          >
                            {formikProps.values.header}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: 400,
                              lineHeight: "16px",
                              fontFamily:
                                '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
                              color: "rgb(168, 168, 168)",
                            }}
                          >
                            {formikProps.values.body}
                          </Typography>
                          <Button
                            sx={{
                              backgroundColor: "#fff",
                              width: "100%",
                              marginTop: "12px",
                            }}
                          >
                            {formikProps.values.buttonText}
                          </Button>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      sx={{ mr: 1 }}
                    >
                      Save
                    </Button>
                    <Button type="button" color="secondary" variant="outlined">
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Form>
        )}
      </Formik>
    </>
  );
}
