export const fetchIP = async () => {
  try {
    const response = await fetch("https://api.ipify.org?format=json");
    return await response.json();

  } catch (error) {
    console.log("Error fetching IP data:", error);
  }
};

export const fetchLocation = async () => {
  try {
    const response = await fetch("https://ipinfo.io/json");
    return await response.json();
    
  } catch (error) {
    console.log("Error fetching location data:", error);
  }
};


export const getBrowserName = () => {
  const { userAgent } = navigator;
  let browserName = "Unknown";

  if (userAgent.includes("Firefox")) {
    browserName = "Firefox";
  } else if (userAgent.includes("SamsungBrowser")) {
    browserName = "Samsung Internet";
  } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
    browserName = "Opera";
  } else if (userAgent.includes("Trident")) {
    browserName = "Internet Explorer";
  } else if (userAgent.includes("Edge")) {
    browserName = "Edge";
  } else if (userAgent.includes("Chrome")) {
    browserName = "Chrome";
  } else if (userAgent.includes("Safari")) {
    browserName = "Safari";
  }

  return browserName;
};

export const getOperatingSystem = () => {
  const { userAgent } = navigator;
  let os = "Unknown";

  if (userAgent.includes("Win")) {
    os = "Windows";
  } else if (userAgent.includes("Mac")) {
    os = "MacOS";
  } else if (userAgent.includes("X11")) {
    os = "UNIX";
  } else if (userAgent.includes("Linux")) {
    os = "Linux";
  } else if (userAgent.includes("Android")) {
    os = "Android";
  } else if (userAgent.includes("like Mac")) {
    os = "iOS";
  }

  return os;
};
