import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import SoftTypography from "../../components/SoftTypography";
import SoftBox from "../../components/SoftBox";
import SoftButton from "../../components/SoftButton";
import { Edit } from "@mui/icons-material";
import Table from "../../examples/Tables/Table";
import SoftInput from "../../components/SoftInput";
import { useDispatch, useSelector } from "react-redux";
import { SET_USER_DATA } from "store";
import { auth } from "Api/Website/auth";
import { useSoftUIController, setLoader } from "../../context";

export default function Index() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData);
  const [isEditing, setIsEditing] = useState(false);
  const [user, setUser] = useState(userData);
  const [, softDispatch] = useSoftUIController();
  const token = useSelector((state) => state.token);
  const [isVerified, setverified] = useState(false);
  const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    dispatch({ type: SET_USER_DATA, payload: userData });
    setUser(userData);
    setIsEditing(false);
  };

  const handleSaveClick = async (event) => {
    event.preventDefault();
    setLoader(softDispatch, true);
    try {
      const formData = new FormData(event.currentTarget);
      const updatedUserData = {
        email: formData.get("email"),
        userName: formData.get("userName"),
      };
      const profile = await auth.editProfile(updatedUserData, token);
      if (profile.success === true) {
        dispatch({
          type: SET_USER_DATA,
          payload: {
            ...userData,
            ...updatedUserData,
          },
        });
      }
      setIsEditing(false);
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        setLoader(softDispatch, false);
      }, 500);
    }
  };

  const createKeys = async () => {
    setLoader(softDispatch, true);
    try {
      const keys = await auth.generateKeys(token);
      if (keys.success === true) {
        dispatch({
          type: SET_USER_DATA,
          payload: {
            ...userData,
            clients: keys.data,
          },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        setLoader(softDispatch, false);
      }, 500);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  // Log userData only once when the component mounts
  useEffect(() => {
    // console.log({ userData });
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} component="form" onSubmit={handleSaveClick}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <SoftTypography variant="h5" fontWeight="medium" color="h5">
            User
          </SoftTypography>
          {!isEditing && (
            <SoftButton onClick={handleEditClick}>
              Edit <Edit />
            </SoftButton>
          )}
        </SoftBox>
        <hr />
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <SoftTypography variant="body1" fontSize={15} fontWeight="light">
              Email
            </SoftTypography>
          </Grid>
          <Grid item xs={9}>
            {isEditing ? (
              <SoftInput
                fullWidth
                name="email"
                value={user.email}
                onChange={handleChange}
                required
                id="email"
                label="Email Address"
                autoComplete="email"
                autoFocus
              />
            ) : (
              <SoftTypography variant="body1" fontSize={15} fontWeight="light">
                {user?.email}
              </SoftTypography>
            )}
          </Grid>
          <Grid item xs={3}>
            <SoftTypography variant="body1" fontSize={15} fontWeight="light">
              Name
            </SoftTypography>
          </Grid>
          <Grid item xs={9}>
            {isEditing ? (
              <SoftInput
                fullWidth
                name="userName"
                onChange={handleChange}
                value={user.userName}
              />
            ) : (
              <SoftTypography variant="body1" fontSize={15} fontWeight="light">
                {user?.userName}
              </SoftTypography>
            )}
          </Grid>
        </Grid>
        {isEditing && (
          <SoftBox display="flex" justifyContent="end" mt={2}>
            <SoftButton
              onClick={handleCancelClick}
              color="info"
              variant="outlined"
              sx={{ mr: 1 }}
            >
              Cancel
            </SoftButton>
            <SoftButton type="submit" color="info" variant="gradient">
              Save
            </SoftButton>
          </SoftBox>
        )}
      </Grid>
      <Grid item xs={12} mt={5}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <SoftBox>
            <SoftTypography variant="h5" fontWeight="medium" color="h5">
              Development Keys
            </SoftTypography>
            <SoftTypography variant="body2" fontWeight="lighter" fontSize={13}>
              Create your own key to integrate QuickAssistant
            </SoftTypography>
          </SoftBox>
          <SoftButton
            color="info"
            fontSize={12}
            onClick={createKeys}
            variant="outlined"
          >
            Create
          </SoftButton>
        </SoftBox>
      </Grid>
      <Grid item xs={12}>
        <Table
          columns={[
            { name: "clientId" },
            { name: "clientSecret" },
            { name: "timestamp" },
          ]}
          rows={userData?.clients}
          setIsPasswordDialogOpen={setIsPasswordDialogOpen}
          isPasswordDialogOpen={isPasswordDialogOpen}
          setverified={setverified}
          isVerified={isVerified}
        />
      </Grid>
      <Grid item xs={12} mt={5}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <SoftBox>
            <SoftTypography variant="h5" fontWeight="medium" color="h5">
              Login Sessions
            </SoftTypography>
            <SoftTypography variant="body2" fontWeight="lighter" fontSize={13}>
              Places where you're logged into Stripe.
            </SoftTypography>
          </SoftBox>
          <SoftButton>Sign out all other sessions.</SoftButton>
        </SoftBox>
      </Grid>
      <Grid item xs={12}>
        <Table
          columns={[
            { name: "location" },
            { name: "device" },
            { name: "ip" },
            { name: "time" },
            { name: "" },
          ]}
          rows={[
            {
              location: "Chandigarh",
              device: "Chrome - Windows",
              ip: "103.187.97.77",
              time: "20 minutes ago",
              action: "Current session",
            },
            {
              location: "Mohali",
              device: "Chrome - Windows",
              ip: "103.187.97.77",
              time: "last week",
              action: "Expired",
            },
          ]}
        />
      </Grid>
      <Grid item xs={12} mt={5}>
        <SoftBox>
          <SoftBox>
            <SoftTypography variant="h5" fontWeight="medium" color="h5">
              Accounts
            </SoftTypography>
            <SoftTypography variant="body2" fontWeight="lighter" fontSize={13}>
              The list of accounts to which you are a member.
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </Grid>
      <Grid item xs={12}>
        <Table
          columns={[{ name: "name" }, { name: "role" }, { name: "id" }]}
          rows={[
            {
              name: "Dinesh Kumar",
              role: "Administrator (Owner)",
              id: "acct_1NyY12EAZKpMnlw4",
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}
